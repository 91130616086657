.charts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.chart-wrapper {
  width: 80%;
  height: 400px; /* Adjust the height as needed */
  max-width: 1000px; /* Optional max width */
}

@media (max-width: 768px) {
  .chart-wrapper {
    width: 100%;
    height: 300px; /* Adjust height for smaller screens */
  }
}
